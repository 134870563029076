import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InputNumberFormat from 'react-number-format';
import { useExpanded, useTable } from 'react-table';

import { Plus as PlusIcon } from '@styled-icons/bootstrap/Plus';
import { Cog as CogIcon } from '@styled-icons/boxicons-solid/Cog';
import { DownArrow as DownArrowIcon } from '@styled-icons/boxicons-solid/DownArrow';
import { RightArrow as RightArrowIcon } from '@styled-icons/boxicons-solid/RightArrow';
import { Save as SaveIcon } from '@styled-icons/boxicons-solid/Save';
import { TrashAlt as TrashAltIcon } from '@styled-icons/boxicons-solid/TrashAlt';
import { TrafficCone as TrafficConeIcon } from '@styled-icons/entypo/TrafficCone';
import { Close as CloseIcon } from '@styled-icons/evaicons-solid/Close';
import { StyledIcon } from '@styled-icons/styled-icon';
import { MdiReactIconComponentType } from 'mdi-react';
import BricksIcon from 'mdi-react/BricksIcon';
import CardAccountDetails from 'mdi-react/CardAccountDetailsIcon';
import GroupIcon from 'mdi-react/GroupIcon';
import InfoIcon from 'mdi-react/InfoCircleOutlineIcon';
import ShovelIcon from 'mdi-react/ShovelIcon';
import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  HStack,
  Flex,
  Box,
  Skeleton,
  InputGroup,
  InputRightAddon,
  Input,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';

import Loader from 'components/Loader';

import useAlert from 'hooks/useAlert';
import useModal from 'hooks/useModal';

import { Budget } from 'types/budget';

import FragmentTableRow from './FragmentsTableRow';
import { FragmentResource, Row } from './types';

export type FragmentSummary = {
  total: number;
  material: number;
  material_percent: number;
  equipment: number;
  equipment_percent: number;
  labor: number;
  labor_percent: number;
  third_party: number;
  third_party_percent: number;
  other: number;
  other_percent: number;
};

type QttySummary = {
  total_compositions: number;
  total_inputs: number;
};

type Props = {
  budget_id: number;
  budget_bases: Budget['bases'];
  component_id: number;
  locale_key: string;
  locale_id: number;
  price_type_id: number;
  price_type_key: string;
  isSubRow?: boolean;
  parentFragments?: FragmentResource[];
  parentQttySummary?: QttySummary;
  registerLoadFragments?: (fn: () => Promise<void>) => void;
  reloadTable: () => void;
  getSummary: () => void;
};

type IsEditingFragment = {
  [id: number]: {
    coefficient: boolean;
    price: boolean;
  };
};

const FragmentsTable: React.FC<Props> = ({
  isSubRow = false,
  parentFragments = [],
  registerLoadFragments,
  budget_id,
  budget_bases,
  component_id,
  locale_id,
  locale_key,
  price_type_id,
  price_type_key,
  getSummary,
  reloadTable,
}) => {
  const { openModal } = useModal();
  const { openAlert } = useAlert();

  const [fragments, setFragments] = useState<FragmentResource[]>([]);
  const [isEditingFragment, setIsEditingFragment] = useState<IsEditingFragment>(
    {},
  );

  const [qttySummary, setQttySummary] = useState<QttySummary>(
    {} as QttySummary,
  );
  const [loading, setLoading] = useState(false);

  const getData = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(
        `budget/${budget_id}/composition/${component_id}/component/fragment`,
        {
          params: {
            page: 1,
            per_page: 999,
            'filter[locale_key]': locale_key,
            'filter[price_type_key]': price_type_key,
          },
        },
      );

      const fragmentsLoaded = response.data;

      const newQttySummary = {
        total_compositions: fragmentsLoaded.summary.total.total_compositions,
        total_inputs: fragmentsLoaded.summary.total.total_inputs,
      };

      setFragments(
        fragmentsLoaded.data.map((i: FragmentResource) => ({
          ...i,
          newQuantity: i.coefficient,
          newUnitPrice: i.unit_price,
        })),
      );
      setQttySummary(newQttySummary);
    } catch (err) {
      setFragments([]);
      toast({
        description:
          err.response?.data?.message ||
          'Houve um erro ao carregar o mapeamento da composição.',
        status: 'error',
      });
      setQttySummary({} as QttySummary);
    } finally {
      setLoading(false);
    }
  }, [component_id, locale_key, price_type_key, budget_id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const isInput = (data: FragmentResource): boolean => {
    return data.fragment_type.includes('input');
  };

  const getType = (row: Row): string => {
    return row.original.fragment_type;
  };

  const getIcon = (row: Row): MdiReactIconComponentType => {
    if (getType(row).includes('input')) {
      return InfoIcon;
    }

    return GroupIcon;
  };

  const shouldShowInputIcon = (i: FragmentResource): boolean => {
    return ['M', 'E', 'L', 'S'].includes(i.fragment.classification.key);
  };

  type InputIcon = MdiReactIconComponentType | StyledIcon | undefined;
  const getInputIcon = useCallback((i: FragmentResource): InputIcon => {
    const value = i.fragment?.classification?.key;

    if (!value) {
      return undefined;
    }

    if (value === 'M') {
      return BricksIcon;
    }

    if (value === 'E') {
      return TrafficConeIcon;
    }

    if (value === 'L') {
      return ShovelIcon;
    }

    if (value === 'S') {
      return CardAccountDetails;
    }

    return undefined;
  }, []);

  const getInputIconType = useCallback((i: FragmentResource): string => {
    const value = i.fragment?.classification?.key;

    if (!value) {
      return '';
    }

    if (value === 'M') {
      return 'Material';
    }

    if (value === 'E') {
      return 'Equipamento';
    }

    if (value === 'L') {
      return 'Mão de obra';
    }

    if (value === 'S') {
      return 'Serviço de terceiros';
    }

    return '';
  }, []);

  const handleRemoveFragment = useCallback(
    (resource: FragmentResource): void => {
      openAlert({
        type: 'danger',
        title: 'Excluir fragmento',
        description: () => (
          <Flex flexDirection="column" width="100%">
            <Text>Você está prestes a excluir um fragmento da composição.</Text>
            <Text fontWeight="bold">{resource.fragment.description}</Text>
            <Text>Deseja realmente realizar essa ação?</Text>
          </Flex>
        ),
        buttons: ['Cancelar', 'Excluir'],
        onConfirm: async () => {
          try {
            await api.delete(
              `budget/${budget_id}/composition/${component_id}/component/fragment/${resource.id}`,
            );

            toast({
              description: 'Fragmento removido com sucesso!',
              status: 'success',
            });

            if (!reloadTable) {
              setFragments((oldFragments) => {
                const newFragments = [...oldFragments];
                const index = newFragments.findIndex(
                  (i) => i.id === resource.id,
                );

                newFragments.splice(index, 1);

                return newFragments;
              });
            } else {
              reloadTable();
            }

            getSummary();
          } catch (err) {
            toast({
              description:
                err.response?.data?.message ||
                'Houve um erro ao excluir o fragmento.',
              status: 'error',
            });
          }
        },
      });
    },
    [reloadTable, openAlert, budget_id, component_id, getSummary],
  );

  const handleUpdateQuantity = useCallback(
    async (component) => {
      if (!component.newQuantity) {
        toast({
          description: 'A quantidade deve ser maior que zero.',
          status: 'error',
        });
        return;
      }

      setLoading(true);

      try {
        await api.put(
          `budget/${budget_id}/composition/${component_id}/component/fragment/${component.id}`,
          {
            fragment: {
              coefficient: component.newQuantity,
            },
          },
        );

        toast({
          description: 'Fragmento alterado com sucesso!',
          status: 'success',
        });

        reloadTable();
        getSummary();
      } catch (err) {
        toast({
          description:
            err.response?.data?.message ||
            'Houve um erro ao editar o fragmento.',
          status: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [budget_id, getSummary, reloadTable, component_id],
  );

  const handleUpdateUnitPrice = useCallback(
    async (component) => {
      setLoading(true);

      try {
        await api.put(
          `budget/${budget_id}/input/${component.fragment.id}/price/${component.price_id}`,
          {
            type: 'unit',
            price_type_id,
            locale_id,
            total: component.newUnitPrice,
          },
        );

        toast({
          description: 'Fragmento alterado com sucesso!',
          status: 'success',
        });

        reloadTable();
        getSummary();
      } catch (err) {
        toast({
          description:
            err.response?.data?.message ||
            'Houve um erro ao editar o fragmento.',
          status: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [budget_id, reloadTable, getSummary, locale_id, price_type_id],
  );

  useEffect(() => {
    if (!isSubRow && !!registerLoadFragments) {
      registerLoadFragments(getData);
    }
  }, [getData, isSubRow, registerLoadFragments]);

  const columns = useMemo(
    () => [
      {
        Header: () => 'Tipo',
        id: 'expander',
        width: '5%',
        Cell: ({ row }: { row: Row }) => {
          return (
            <HStack>
              <Box title={isInput(row.original) ? 'Insumo' : 'Composição'}>
                <Icon w={4} h={4} as={getIcon(row)} />
              </Box>

              {isInput(row.original) && shouldShowInputIcon(row.original) && (
                <Box title={getInputIconType(row.original)}>
                  <Icon w={4} h={4} as={getInputIcon(row.original)} />
                </Box>
              )}

              {getType(row).includes('composition') &&
                (row.isExpanded ? (
                  <Icon w={4} h={4} as={DownArrowIcon} />
                ) : (
                  <Icon w={4} h={4} as={RightArrowIcon} />
                ))}
            </HStack>
          );
        },
      },
      {
        Header: 'Código',
        accessor: 'data.code',
        width: '1%',
        Cell: ({ row }: { row: Row }) => {
          return `${row.original.fragment.code} / ${row.original.fragment.version.base.description}`;
        },
      },
      {
        Header: 'Descrição',
        accessor: 'data.description',
        width: '40%',
        Cell: ({ row }: { row: Row }) => {
          return (
            <Text
              maxWidth={{ base: '100%', md: '768px' }}
              whiteSpace="pre-wrap"
            >
              {row.original.fragment.description}
            </Text>
          );
        },
      },
      {
        Header: 'Unidade',
        accessor: 'data.unit_measure_id',
        width: '1%',
        Cell: ({ row }: { row: Row }) => {
          return row.original.fragment.unit_measure.description;
        },
      },
      {
        Header: 'Coeficiente',
        accessor: 'data.coefficient',
        id: 'coefficient',
        isNumeric: true,
        width: Object.values(isEditingFragment).some((item) => item.coefficient)
          ? '20%'
          : '1%',
        Cell: ({ row }: { row: Row }) => {
          if (isEditingFragment[row.original.id]?.coefficient) {
            return (
              <InputGroup>
                <InputNumberFormat
                  type="tel"
                  displayType="input"
                  thousandSeparator="."
                  decimalSeparator=","
                  allowNegative={false}
                  decimalScale={7}
                  value={row.original.newQuantity}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      setIsEditingFragment({
                        ...isEditingFragment,
                        [row.original.id]: {
                          ...isEditingFragment[row.original.id],
                          coefficient: false,
                        },
                      });
                      handleUpdateQuantity(row.original);
                    }
                  }}
                  onValueChange={(v) => {
                    setFragments((oldData) => {
                      const newData = [...oldData];
                      const index = newData.findIndex(
                        (item) => item.id === row.original.id,
                      );
                      newData[index].newQuantity = v.floatValue;
                      return newData;
                    });
                  }}
                  customInput={Input}
                  style={{
                    borderTopRightRadius: '0',
                    borderBottomRightRadius: '0',
                    textAlign: 'right',
                    color: 'black',
                  }}
                />

                <InputRightAddon px={0}>
                  <IconButton
                    height="100%"
                    colorScheme="blue"
                    aria-label="Salvar quantidade"
                    title="Salvar quantidade"
                    borderRadius="0"
                    onClick={() => {
                      setIsEditingFragment({
                        ...isEditingFragment,
                        [row.original.id]: {
                          ...isEditingFragment[row.original.id],
                          coefficient: false,
                        },
                      });

                      handleUpdateQuantity(row.original);
                    }}
                    width="30px"
                  >
                    <Icon w={4} h={4} as={SaveIcon} />
                  </IconButton>

                  <IconButton
                    height="100%"
                    colorScheme="red"
                    aria-label="Cancelar"
                    title="Cancelar"
                    borderTopLeftRadius="0"
                    borderBottomLeftRadius="0"
                    onClick={() => {
                      setIsEditingFragment({
                        ...isEditingFragment,
                        [row.original.id]: {
                          ...isEditingFragment[row.original.id],
                          coefficient: false,
                        },
                      });
                    }}
                    width="30px"
                  >
                    <Icon w={4} h={4} as={CloseIcon} />
                  </IconButton>
                </InputRightAddon>
              </InputGroup>
            );
          }

          return (
            <>
              {row.original.coefficient_was_changed && (
                <Text size="small" color="red.500" textDecor="line-through">
                  {Intl.NumberFormat('pt-BR', {
                    maximumFractionDigits: 7,
                  }).format(row.original.coefficient_original || 0)}
                </Text>
              )}

              <Text
                color={row.isExpanded ? 'orange.400' : 'blue.500'}
                textDecor="underline"
                cursor="pointer"
                onClick={() => {
                  setIsEditingFragment({
                    ...isEditingFragment,
                    [row.original.id]: {
                      ...isEditingFragment[row.original.id],
                      coefficient: true,
                    },
                  });
                }}
              >
                {Intl.NumberFormat('pt-BR', {
                  maximumFractionDigits: 7,
                }).format(row.original.coefficient || 0)}
              </Text>
            </>
          );
        },
      },
      {
        Header: 'Valor unitário',
        accessor: 'data.unit_value',
        isNumeric: true,
        width: Object.values(isEditingFragment).some((item) => item.price)
          ? '20%'
          : '1%',
        Cell: ({ row }: { row: Row }) => {
          if (!isInput(row.original)) {
            return Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(row.original.unit_price || 0);
          }

          if (isEditingFragment[row.original.id]?.price) {
            return (
              <InputGroup>
                <InputNumberFormat
                  type="tel"
                  displayType="input"
                  thousandSeparator="."
                  decimalSeparator=","
                  allowNegative={false}
                  prefix="R$ "
                  decimalScale={2}
                  fixedDecimalScale
                  value={row.original.newUnitPrice}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      setIsEditingFragment({
                        ...isEditingFragment,
                        [row.original.id]: {
                          ...isEditingFragment[row.original.id],
                          price: false,
                        },
                      });

                      handleUpdateUnitPrice(row.original);
                    }
                  }}
                  onValueChange={(v) => {
                    setFragments((oldData) => {
                      const newData = [...oldData];
                      const index = newData.findIndex(
                        (item) => item.id === row.original.id,
                      );
                      newData[index].newUnitPrice = v.floatValue || 0;
                      return newData;
                    });
                  }}
                  customInput={Input}
                  style={{
                    borderTopRightRadius: '0',
                    borderBottomRightRadius: '0',
                    textAlign: 'right',
                    color: 'black',
                  }}
                />
                <InputRightAddon px={0}>
                  <IconButton
                    height="100%"
                    colorScheme="blue"
                    aria-label="Salvar quantidade"
                    title="Salvar quantidade"
                    borderRadius="0"
                    onClick={() => {
                      setIsEditingFragment({
                        ...isEditingFragment,
                        [row.original.id]: {
                          ...isEditingFragment[row.original.id],
                          price: false,
                        },
                      });

                      handleUpdateUnitPrice(row.original);
                    }}
                    width="30px"
                  >
                    <Icon w={4} h={4} as={SaveIcon} />
                  </IconButton>

                  <IconButton
                    height="100%"
                    colorScheme="red"
                    aria-label="Cancelar"
                    title="Cancelar"
                    borderTopLeftRadius="0"
                    borderBottomLeftRadius="0"
                    onClick={() => {
                      setIsEditingFragment({
                        ...isEditingFragment,
                        [row.original.id]: {
                          ...isEditingFragment[row.original.id],
                          price: false,
                        },
                      });
                    }}
                    width="30px"
                  >
                    <Icon w={4} h={4} as={CloseIcon} />
                  </IconButton>
                </InputRightAddon>
              </InputGroup>
            );
          }

          return (
            <>
              {row.original.unit_price_was_changed && (
                <Text size="small" color="red.500" textDecor="line-through">
                  {Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(row.original.unit_price_original || 0)}
                </Text>
              )}

              <Text
                color={row.isExpanded ? 'orange.400' : 'blue.500'}
                textDecor="underline"
                cursor="pointer"
                onClick={() => {
                  setIsEditingFragment({
                    ...isEditingFragment,
                    [row.original.id]: {
                      ...isEditingFragment[row.original.id],
                      price: true,
                    },
                  });
                }}
              >
                {Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(row.original.unit_price || 0)}
              </Text>
            </>
          );
        },
      },
      {
        Header: 'Total',
        accessor: 'data.total',
        isNumeric: true,
        width: '1%',
        Cell: ({ row }: { row: Row }) => {
          const value = row.original.total;

          return Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(value || 0);
        },
      },
      {
        Header: 'Ações',
        id: 'actions',
        width: '5%',
        Cell: ({ row }: { row: Row }) => {
          return (
            <Menu>
              <MenuButton
                as={IconButton}
                colorScheme="orange"
                icon={<Icon as={CogIcon} />}
              />

              <MenuList>
                {!isInput(row.original) && (
                  <>
                    <MenuItem
                      icon={<PlusIcon size={16} />}
                      title="Adicionar composição"
                      color="black"
                      onClick={() => {
                        openModal('budgetCompositionFragmentSelect', {
                          mode: 'select',
                          data: {
                            budgetId: budget_id,
                            compositionId: row.original.fragment.id,
                            bases: budget_bases,
                          },
                          onConfirm: () => {
                            reloadTable();
                            getSummary();
                          },
                        });
                      }}
                    >
                      Adicionar composição
                    </MenuItem>

                    <MenuItem
                      icon={<PlusIcon size={16} />}
                      title="Adicionar insumo"
                      color="black"
                      onClick={() => {
                        openModal('budgetInputFragmentSelect', {
                          mode: 'select',
                          data: {
                            budgetId: budget_id,
                            compositionId: row.original.fragment.id,
                            bases: budget_bases,
                          },
                          onConfirm: () => {
                            reloadTable();
                            getSummary();
                          },
                        });
                      }}
                    >
                      Adicionar insumo
                    </MenuItem>
                  </>
                )}

                <MenuItem
                  icon={<TrashAltIcon size={14} />}
                  title={
                    isInput(row.original)
                      ? 'Remover insumo'
                      : 'Remover composição'
                  }
                  onClick={() => handleRemoveFragment(row.original)}
                  color="red.600"
                >
                  {isInput(row.original)
                    ? 'Remover insumo'
                    : 'Remover composição'}
                </MenuItem>
              </MenuList>
            </Menu>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getInputIcon, isEditingFragment, reloadTable],
  );

  const getRowId = useCallback((row) => {
    return row.id;
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable(
    {
      // @ts-expect-error types
      columns,
      data: fragments,
      getRowId,
      autoResetExpanded: false,
    },
    useExpanded,
  );

  const renderRowSubComponent = useCallback(
    ({ nextComponentId, nextComponentPriceTypeKey }) => (
      <FragmentsTable
        isSubRow
        parentFragments={fragments}
        budget_id={budget_id}
        budget_bases={budget_bases}
        component_id={nextComponentId}
        locale_key={locale_key}
        locale_id={locale_id}
        price_type_id={price_type_id}
        price_type_key={nextComponentPriceTypeKey}
        getSummary={getSummary}
        reloadTable={reloadTable}
      />
    ),
    [
      locale_id,
      locale_key,
      price_type_id,
      fragments,
      budget_id,
      budget_bases,
      getSummary,
      reloadTable,
    ],
  );

  const parent = useMemo(() => {
    return parentFragments.find((f) => f.fragment.id === component_id);
  }, [parentFragments, component_id]);

  return (
    <>
      {isSubRow && (
        <Flex
          color="white"
          width="100%"
          flexDirection={{ base: 'column', lg: 'row' }}
          mb={8}
        >
          <>
            <Box
              my={{ base: 2, md: 0 }}
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <Text fontWeight="600">Classe</Text>
              <Text fontSize="smaller" fontStyle="italic" mt={2}>
                {loading ? (
                  <Skeleton width={150} height={4} />
                ) : (
                  <>{parent?.fragment.type.classification.description || '-'}</>
                )}
              </Text>
            </Box>

            <Box
              my={{ base: 2, md: 0 }}
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <Text fontWeight="600">Tipo de preço</Text>
              <Text fontSize="smaller" fontStyle="italic" mt={2}>
                {loading ? (
                  <Skeleton width={150} height={4} />
                ) : (
                  <>{parent?.fragment.type.description || '-'}</>
                )}
              </Text>
            </Box>

            <Box
              my={{ base: 2, md: 0 }}
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <Text fontWeight="600">Composições</Text>
              <Text fontSize="smaller" fontStyle="italic" mt={2}>
                {loading ? (
                  <Skeleton width={150} height={4} />
                ) : (
                  <>{qttySummary.total_compositions}</>
                )}
              </Text>
            </Box>

            <Box
              my={{ base: 2, md: 0 }}
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <Text fontWeight="600">Insumos</Text>
              <Text fontSize="smaller" fontStyle="italic" mt={2}>
                {loading ? (
                  <Skeleton width={150} height={4} />
                ) : (
                  <> {qttySummary.total_inputs}</>
                )}
              </Text>
            </Box>
          </>
        </Flex>
      )}

      <TableContainer width="100%">
        <Table {...getTableProps()} borderBottom="none" variant="simple">
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    {...column.getHeaderProps()}
                    width={column.width}
                    // @ts-expect-error isNumeric does not exists on lib type
                    isNumeric={column.isNumeric}
                  >
                    {column.render('Header')}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {!loading &&
              rows.length > 0 &&
              rows.map((row) => {
                prepareRow(row);

                return (
                  <FragmentTableRow
                    row={row as unknown as Row}
                    visibleColumns={visibleColumns}
                    renderRowSubComponent={renderRowSubComponent}
                    {...row.getRowProps()}
                  />
                );
              })}

            {loading && (
              <Tr>
                <Td
                  colSpan={visibleColumns.length}
                  borderBottom="none"
                  background="gray.100"
                >
                  <Loader />
                </Td>
              </Tr>
            )}

            {!loading && fragments.length === 0 && (
              <Tr>
                <Td
                  colSpan={visibleColumns.length}
                  borderBottom="none"
                  background="gray.100"
                  className="text-center"
                >
                  Nenhum resultado encontrado.
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default FragmentsTable;
