import React, { useEffect, useState } from 'react';

import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

type FormErrors = {
  [key: string]: string | boolean;
  type: 'invalid' | 'required';
};

interface ModalPositionUpdateProps extends ModalRootProps {
  data: {
    budget_id: number;
    item_id: number;
    code: string;
    description: string;
    position: string;
    constructive_unit_position?: string;
  };
}

const ModalPositionUpdate: React.FC<ModalPositionUpdateProps> = ({
  onConfirm,
  handleClose,
  data,
  ...restProps
}) => {
  const [position, setPosition] = useState<string>();
  const positionRef = React.useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState<FormErrors | undefined>();

  useEffect(() => {
    if (positionRef.current) {
      positionRef.current.focus();
    }
  }, []);

  const handleConfirm = async (): Promise<void> => {
    const formErrors: FormErrors = {} as FormErrors;

    if (!position) {
      Object.assign(formErrors, { position: true, type: 'required' });
    }

    setErrors(formErrors);
    if (Object.keys(formErrors).length) {
      return;
    }

    try {
      setLoading(true);

      const targetPosition = data.constructive_unit_position
        ? `${data.constructive_unit_position}.${position}`
        : String(position);

      await api.put(
        `/budget/${data.budget_id}/component/${data.item_id}/move`,
        {
          location: targetPosition,
        },
      );

      if (onConfirm) onConfirm();

      toast({
        description: 'Componente movido com sucesso!',
        status: 'success',
      });

      handleClose();
    } catch (err) {
      toast({
        description:
          err.response?.data?.message || 'Houve um erro ao mover o componente!',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal {...restProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Alterar posição</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <FormControl marginTop="4">
            <FormLabel>Código</FormLabel>
            <Text fontWeight="bold">{data.code}</Text>
          </FormControl>

          <FormControl marginTop="4">
            <FormLabel>Descrição</FormLabel>
            <Text fontWeight="bold">{data.description}</Text>
          </FormControl>

          <FormControl marginTop="4">
            <FormLabel>Posição atual</FormLabel>
            <Text fontWeight="bold">{data.position}</Text>
          </FormControl>

          <FormControl marginTop="4" isInvalid={!!errors?.position}>
            <FormLabel>Nova posição</FormLabel>

            <Input
              ref={positionRef}
              w="100%"
              type="text"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleConfirm()}
            />
            <FormErrorMessage>Nova posição é obrigatória</FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={loading}
            isDisabled={loading}
            colorScheme="green"
            onClick={handleConfirm}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalPositionUpdate;
