import React, { useCallback, useEffect, useState } from 'react';

import { Plus as PlusIcon } from '@styled-icons/bootstrap/Plus';
import { DollarSign as DollarSignIcon } from '@styled-icons/fa-solid/DollarSign';
import { Refresh as RefreshIcon } from '@styled-icons/material-twotone/Refresh';
import { api } from 'services/api';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Flex,
  Text,
  Box,
  useMediaQuery,
  ModalFooter,
  HStack,
  Button,
  Icon,
  IconButton,
  ModalCloseButton,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';
import SummaryContainer from 'components/SummaryContainer';
import SummaryDataChartCard from 'components/SummaryDataChartCard';

import useModal from 'hooks/useModal';

import { Budget } from 'types/budget';

import FragmentsTable, { FragmentSummary } from './FragmentsTable';

type Props = ModalRootProps & {
  data: {
    id: number;
    budget_id: number;
    budget_bases: Budget['bases'];
    locale_id: number;
    locale_key: string;
    price_type_id: number;
    price_type_key: string;
    code: string;
    base: string;
    description: string;
    price: number;
    classification: string;
    type: string;
    unit_measure: string;
  };
};

const modalMargin = 120;
const modalMobileFix = 100;

type LoadFragmentsFn = () => Promise<void>;
let loadFragmentsFn: LoadFragmentsFn;

const ModalCompositionEdit: React.FC<Props> = ({
  data,
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState<FragmentSummary>(
    {} as FragmentSummary,
  );

  const { openModal } = useModal();

  const [isMobile] = useMediaQuery('(max-width: 728px)');

  const getSummary = useCallback(async () => {
    setLoading(true);
    setSummary({} as FragmentSummary);

    try {
      const { data: response } = await api.get(
        `budget/${data.budget_id}/composition/${data.id}/price`,
        {
          params: {
            'filter[locale_id]': data.locale_id,
            'filter[price_type_id]': data.price_type_id,
          },
        },
      );

      const [summaryLoaded] = response.data;

      setSummary({
        total: summaryLoaded.total,
        material: summaryLoaded.material,
        material_percent: summaryLoaded.material_percent,
        equipment: summaryLoaded.equipment,
        equipment_percent: summaryLoaded.equipment_percent,
        labor: summaryLoaded.labor,
        labor_percent: summaryLoaded.labor_percent,
        third_party: summaryLoaded.third_party,
        third_party_percent: summaryLoaded.third_party_percent,
        other: summaryLoaded.other,
        other_percent: summaryLoaded.other_percent,
      });
    } catch (err) {
      setSummary({} as FragmentSummary);
    } finally {
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    getSummary();
  }, [getSummary]);

  const registerLoadFragments = useCallback((fn: LoadFragmentsFn) => {
    loadFragmentsFn = fn;
  }, []);

  const handleConfirm = (): void => {
    if (onConfirm) {
      onConfirm();
    }

    handleClose();
  };

  return (
    <Modal {...restProps} scrollBehavior="inside" closeOnEsc={false}>
      <ModalOverlay />
      <ModalContent
        sx={{
          width: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
          maxWidth: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
          height: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
          maxHeight: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
        }}
      >
        <ModalHeader>Editar composição</ModalHeader>

        <ModalCloseButton onClick={handleConfirm} />

        <ModalBody>
          <SummaryContainer loading={loading}>
            <SummaryDataChartCard
              idx={1}
              isHidden={false}
              typeNumber="number"
              data={{
                icon: DollarSignIcon,
                title: 'VALOR TOTAL',
                number: summary.total || 0,
                formattedNumber: Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(summary.total || 0),
                colorSchemeCard: 'green',

                chart: {
                  colors: [
                    'rgb(237, 137, 54)',
                    'rgb(30, 108, 168)',
                    'rgb(33, 186, 69)',
                    'rgb(147,112,219)',
                    'rgb(251, 190, 8)',
                  ],
                  labels: [
                    `Material (${Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 2,
                    }).format(summary.material_percent || 0)}%)`,
                    `Mão de Obra (${Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 2,
                    }).format(summary.labor_percent || 0)}%)`,
                    `Equipamento (${Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 2,
                    }).format(summary.equipment_percent || 0)}%)`,
                    `Serviços de terceiros (${Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 2,
                    }).format(summary.third_party_percent || 0)}%)`,
                    `Outros (${Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 2,
                    }).format(summary.other_percent || 0)}%)`,
                  ],
                  series: [
                    summary.material || 0,
                    summary.labor || 0,
                    summary.equipment || 0,
                    summary.third_party || 0,
                    summary.other || 0,
                  ],
                },
              }}
            />
          </SummaryContainer>

          <Flex
            width="100%"
            my={4}
            flexDirection={{ base: 'column', md: 'row' }}
          >
            <Box
              my={{ base: 2, md: 0 }}
              display="flex"
              flexDirection="column"
              width={{ base: '100%', md: '16%' }}
            >
              <Text fontWeight="600">Código</Text>
              <Text fontSize="smaller">
                {data.code} / {data.base}
              </Text>
            </Box>

            <Box
              my={{ base: 2, md: 0 }}
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <Text fontWeight="600">Descrição</Text>
              <Text fontSize="smaller" fontStyle="italic">
                {data.description}
              </Text>
            </Box>
          </Flex>

          <Flex
            width="100%"
            my={4}
            flexDirection={{ base: 'column', md: 'row' }}
          >
            <Box
              my={{ base: 2, md: 0 }}
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <Text fontWeight="600">Valor unitário</Text>

              <Text as="span" fontSize="smaller" fontStyle="italic">
                {Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(summary.total || 0)}
              </Text>
            </Box>

            <Box
              my={{ base: 2, md: 0 }}
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <Text fontWeight="600">Classe</Text>
              <Text fontSize="smaller" fontStyle="italic">
                {data.classification}
              </Text>
            </Box>

            <Box
              my={{ base: 2, md: 0 }}
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <Text fontWeight="600">Tipo</Text>
              <Text fontSize="smaller" fontStyle="italic">
                {data.type}
              </Text>
            </Box>

            <Box
              my={{ base: 2, md: 0 }}
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <Text fontWeight="600">Unidade</Text>
              <Text fontSize="smaller" fontStyle="italic">
                {data.unit_measure}
              </Text>
            </Box>
          </Flex>

          <HStack my={5}>
            <Button
              isLoading={loading}
              isDisabled={loading}
              leftIcon={<Icon as={PlusIcon} />}
              colorScheme="orange"
              size="md"
              onClick={() => {
                openModal('budgetCompositionFragmentSelect', {
                  mode: 'select',
                  data: {
                    budgetId: data.budget_id,
                    compositionId: data.id,
                    bases: data.budget_bases,
                  },
                  onConfirm: () => {
                    loadFragmentsFn();
                    getSummary();
                  },
                });
              }}
            >
              Adicionar composição
            </Button>

            <Button
              isLoading={loading}
              isDisabled={loading}
              colorScheme="green"
              leftIcon={<Icon as={PlusIcon} />}
              size="md"
              onClick={() => {
                openModal('budgetInputFragmentSelect', {
                  mode: 'select',
                  data: {
                    budgetId: data.budget_id,
                    compositionId: data.id,
                    bases: data.budget_bases,
                  },
                  onConfirm: () => {
                    loadFragmentsFn();
                    getSummary();
                  },
                });
              }}
            >
              Adicionar insumo
            </Button>

            <IconButton
              size="md"
              colorScheme="blue"
              title="Atualizar"
              aria-label="Atualizar"
              icon={<Icon as={RefreshIcon} w={5} h={5} />}
              isLoading={loading}
              isDisabled={loading}
              onClick={() => {
                loadFragmentsFn();
                getSummary();
              }}
            />
          </HStack>

          <FragmentsTable
            budget_id={data.budget_id}
            budget_bases={data.budget_bases}
            component_id={data.id}
            locale_id={data.locale_id}
            locale_key={data.locale_key}
            price_type_id={data.price_type_id}
            price_type_key={data.price_type_key}
            registerLoadFragments={registerLoadFragments}
            getSummary={getSummary}
            reloadTable={loadFragmentsFn}
          />
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button
              isLoading={loading}
              isDisabled={loading}
              colorScheme="green"
              size="md"
              onClick={handleConfirm}
            >
              Finalizar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalCompositionEdit;
